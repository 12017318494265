import React, { useState } from 'react';

const InsertForm = () => {
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent traditional form submission

        // Prepare form data as URL-encoded string
        const formData = new URLSearchParams();
        formData.append('name', name);

        try {
            const response = await fetch('insert.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: formData.toString(),
            });

            const result = await response.text();
            setMessage(result); // Update the message based on server response
        } catch (error) {
            setMessage('There was an error submitting the form.');
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <label htmlFor="name">Name:</label>
                <input 
                    type="text" 
                    id="name" 
                    name="name" 
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
                <br /><br />
                <input type="submit" value="Submit" />
            </form>
            {message && <p>{message}</p>} {/* Display message */}
        </div>
    );
};

export default InsertForm;
