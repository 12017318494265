// src/context/SectionContext.js
import React, { createContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const SectionContext = createContext();

export const SectionProvider = ({ children }) => {
  const [sections, setSections] = useState([]);
  const addSection = (name, images) => {
    setSections([...sections, { id: uuidv4(), name, images }]);  // Add a section with a name and an array of images
  };

  return (
    <SectionContext.Provider value={{ sections, addSection }}>
      {children}
    </SectionContext.Provider>
  );
};
