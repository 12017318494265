// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { SectionProvider } from './SectionContext.js';
import MainPage from './MainPage.js';
import ControlPage from './ControlPage.js';
import ControlPanel from './component/ControlPanel.js';

function App() {
  return (
    <>
<ControlPanel/>
    </>
    // <SectionProvider>
    //   <Router>
    //     <nav>
    //       <Link to="/">Home</Link> | <Link to="/add-section">Add Section</Link>
    //     </nav>
    //     <Routes>
    //       <Route path="/" element={<MainPage />} />
    //       <Route path="/add-section" element={<ControlPage />} />
    //     </Routes>
    //   </Router>
    // </SectionProvider>
  );
}

export default App;
